import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import './App.css';
import Grid from '@material-ui/core/Grid';
import { TextField, Button, Typography, Checkbox, Container, Paper, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import moment from 'moment';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    textoExplicativo: {
        fontSize: 12,
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

// @ts-ignore
const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

let _IS_DEV = false

const _URL_API = _IS_DEV ? 'http://localhost:5001/multi_purpose_apis/avaliacao_impactos/cadastra_usuario' :
    'https://mapas.cnpm.embrapa.br/api/multi_purpose_apis/avaliacao_impactos/cadastra_usuario';

const URL_API_CONSULTA_USUARIO = _IS_DEV ? 'http://localhost:5001/multi_purpose_apis/avaliacao_impactos/consulta_usuario' :
    'https://mapas.cnpm.embrapa.br/api/multi_purpose_apis/avaliacao_impactos/consulta_usuario';

const URL_API_CONSULTA_TODOS_USUARIOS = _IS_DEV ? 'http://localhost:5001/multi_purpose_apis/avaliacao_impactos/consulta_todos_usuarios' :
    'https://mapas.cnpm.embrapa.br/api/multi_purpose_apis/avaliacao_impactos/consulta_todos_usuarios';


const _URL_API_MUNICIPIOS = _IS_DEV ? "http://localhost:5001/multi_purpose_apis/municipios/lista_municipios" : "https://mapas.cnpm.embrapa.br/api/multi_purpose_apis/municipios/lista_municipios";

const _LOCAL_STORAGE = '_AVALIACAO_DE_IMPACTOS_CNPM';

const _TITULO_MODAL = 'Cadastro de Usuários do Geoinfo';
const _TEXTO_EXPLICATIVO = 'Por favor, preencha o formulário. Esta identificação é fundamental para que a Embrapa possa aprimorar seus serviços neste ambiente.';

const profissoes = [
    { value: 'Autônomo', label: 'Autônomo' },
    { value: 'Docente', label: 'Docente' },
    { value: 'Estudante - Geo', label: 'Estudante - Geo' },
    { value: 'Pesquisador - Geo', label: 'Pesquisador - Geo' },
    { value: 'Gestor Público/Privado', label: 'Gestor Público/Privado' },
    { value: 'Servidor/Empregado público', label: 'Servidor/Empregado público' },
    { value: 'Outros', label: 'Outros' }
];

const getUrlParameter = (name: any) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const adm = getUrlParameter('adm');

const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}



const App = () => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [municipio, setMunicipio] = useState({ value: '', label: 'Digite sua cidade ...' });
    const [profissao, setProfissao] = useState({ value: '', label: 'Preencha a profissão' });

    const [listaUsuarios, setListaUsuarios] = useState([]);

    const [msg, setMsg] = useState('');

    const [showDialog, setShowDialog] = useState(false);
    const [checkLocalStorage, setCheckLocalStorage] = useState(true);

    const [emailValidado, setEmailValidado] = useState(false);

    // Effect para a inicialização - verifica o localStorage
    useEffect(() => {
        // verifica se o usuario ja preencheu os dados pelo localStorage
        if (!window.localStorage.getItem(_LOCAL_STORAGE)) {
            setShowDialog(true);
        }
    }, []);

    // Effect que carrega a lista de usuarios
    useEffect(() => {
        const exec = async () => {
            if (adm) {
                const response = await axios.post(URL_API_CONSULTA_TODOS_USUARIOS, {});
                setListaUsuarios(response.data);
            }
        }
        exec();
    }, []);

    const verificaEmail = async () => {
        try {
            const result: any = await axios.post(URL_API_CONSULTA_USUARIO, { email: email });
            const usuario = result.data;
            if (usuario) {
                setNome(usuario.nome);
                setTelefone(usuario.telefone);
                setMunicipio({ value: usuario.municipio_geocodigo, label: usuario.municipio_nome });
                setProfissao({ value: usuario.profissao, label: usuario.profissao });
            } else {
                setNome('');
                setTelefone('');
                setMunicipio({ value: '', label: 'Digite sua cidade ...' });
                setProfissao({ value: '', label: 'Preencha a profissão' });
            }
            setEmailValidado(true);
        } catch (e) {
            console.error("Erro ao consultar o usuario : " + e);
        }

    }

    const enviaUsuario = async (event: any) => {
        event.preventDefault();

        // verifica se o municipio e a profissao foram preenchidas
        if (municipio.value === '') {
            setMsg('Por favor preencha sua cidade !');
            return;
        }

        if (profissao.value === '') {
            setMsg('Por favor preencha sua profissão !');
            return;
        }

        // cria o objeto usuario
        const usuario = {
            nome: nome,
            email: email,
            telefone: telefone,
            municipio: municipio.label,
            geocodigo: municipio.value,
            profissao: profissao.value,
            origem: window.location.hostname
        }

        try {
            await axios.post(_URL_API, usuario);
            // grava no localstorage se o usuario escolher nao mais ver a mensagem
            setMsg("Cadastro realizado com sucesso !");
            setTimeout(() => setShowDialog(false), 2000);
            checkLocalStorage && window.localStorage.setItem(_LOCAL_STORAGE, "true");
            console.log("Usuário enviado com sucesso !");
        } catch (e) {
            setMsg("Ocorreu um erro ao realizar o cadastro ! " + e);
            setTimeout(() => setShowDialog(false), 2000);
            console.error("Ocorreu um erro ao realizar o cadastro ! " + e);
        }

    }

    const lerMunicipios = (valor: string) => {
        // verifica se o valor digitado tem mais de 3 letras
        if (valor.length < 3) {
            return;
        }
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(_URL_API_MUNICIPIOS, { filter: valor });
                // converte os municipios recebidos para o padrao do React-Select
                const municipios = response.data.map((m: any) => {
                    return { value: m.geocodigo, label: m.nome + ' - ' + m.uf }
                });
                resolve(municipios);
            } catch (e) {
                console.error("Erro ao ler a lista de municipios : " + e);
                reject("Erro ao ler a lista de municipios : " + e);
            }
        });
    }

    const downloadCSV = async () => {
        const fileDownload = require('js-file-download');
        try {
            const response = await axios.post(URL_API_CONSULTA_TODOS_USUARIOS, { csv: 'true' });
            fileDownload(response.data, 'usuarios_avaliacao_impacto' + moment().format('YYYY-MM-DD HH:MM') + '.csv');
            console.log("Download do CSV executado com sucesso !");
        } catch (e) {
            console.error("Problema ao baixar o CSV : " + e);
        }
    }

    const ListaUsuarios = () => {

        const useStyles = makeStyles((theme) => ({
            root: {
                display: 'flex',
                flexDirection: 'column'
            },
            main: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(1),
                maxWidth: '800px'
            },
            titulo: {
                textAlign: 'center'
            },
            lista: {
                width: '100%',
                backgroundColor: theme.palette.background.paper,
            }
        }));

        const classes = useStyles();
        return (
            <div className={classes.root}>
                <Container component="main" className={classes.main} maxWidth="sm">
                    <Grid container>
                        <Grid item sm={6}>
                            <h2>Lista de Usuários Cadastrados</h2>
                        </Grid>
                        <Grid item sm={6}>
                            <Button onClick={() => downloadCSV()}
                                variant='outlined'
                                color="primary"
                                style={{ width: '100%', marginTop: '20px' }}>
                                Download do CSV
                            </Button>
                        </Grid>
                    </Grid>

                    {
                        listaUsuarios.map((u: any) => {
                            return (
                                <List key={u.municipio_geocodigo} className={classes.lista}>
                                    <Paper>
                                        <ListItem selected={true}>
                                            <Grid container>
                                                <Grid item sm={6}>
                                                    <ListItemText primary="Nome" secondary={u.nome} />
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <ListItemText primary="Data" secondary={moment(u.data).format("DD/MM/YYYY HH:MM")} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider variant="middle" component="li" />
                                        <ListItem>
                                            <Grid container>
                                                <Grid item sm={6}>
                                                    <ListItemText primary="Email" secondary={u.email} />
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <ListItemText primary="Telefone" secondary={u.telefone} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider variant="middle" component="li" />
                                        <ListItem>
                                            <Grid container>
                                                <Grid item sm={6}>
                                                    <ListItemText primary="Municipio" secondary={u.municipio_nome} />
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <ListItemText primary="Profissão" secondary={u.profissao} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider variant="middle" component="li" />
                                        <ListItem>
                                            <ListItemText primary="Histórico de visitas - Geoinfo" secondary={u.origem} />
                                        </ListItem>
                                    </Paper>
                                </List>
                            );
                        })
                    }
                </Container>
            </div>
        );
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h4 id="simple-modal-title">{_TITULO_MODAL}</h4>
            <p className={classes.textoExplicativo}>
                {_TEXTO_EXPLICATIVO}
            </p>

            <form className={classes.root} onSubmit={(e) => enviaUsuario(e)}>
                <TextField
                    id="outlined-basic"
                    value={email}
                    required={true}
                    type="email"
                    onBlur={() => verificaEmail()}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    variant="outlined" />
                <br />
                <AsyncSelect styles={selectStyles}
                    cacheOptions
                    value={municipio}
                    isDisabled={!emailValidado}
                    loadOptions={lerMunicipios}
                    defaultOptions
                    // @ts-ignore
                    onChange={(newValue) => setMunicipio(newValue)}
                />
                <br />
                <TextField
                    id="outlined-basic"
                    disabled={!emailValidado}
                    value={nome}
                    required={true}
                    onChange={(e) => setNome(e.target.value)}
                    label="Nome"
                    variant="outlined" />
                <br />
                <TextField
                    id="outlined-basic"
                    disabled={!emailValidado}
                    value={telefone}
                    type="fone"
                    onChange={(e) => setTelefone(e.target.value)}
                    label="Telefone"
                    variant="outlined" />
                <br />
                <Select
                    options={profissoes}
                    value={profissao}
                    isDisabled={!emailValidado}
                    // @ts-ignore
                    onChange={(newValue) => setProfissao(newValue)}
                />
                <br />
                <Grid container>
                    <Checkbox
                        checked={checkLocalStorage}
                        onChange={(e) => setCheckLocalStorage(e.target.checked)}
                        style={{ marginTop: '-12px' }} />
                    <p>Não mostrar novamente</p>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="stretch"
                >
                    <Button type="submit" variant='contained' color="primary" disabled={!emailValidado} style={{ width: '50%' }}>
                        Enviar
                    </Button>
                    <Button variant='text' color='secondary' onClick={() => {
                        checkLocalStorage && window.localStorage.setItem(_LOCAL_STORAGE, "true");
                        setShowDialog(false);
                    }} style={{ width: '50%' }}>
                        Cancelar
                    </Button>

                </Grid>


                <Typography color="error">
                    {msg}
                </Typography>
            </form>

            <p className={classes.textoExplicativo}>
                Importante:

                Ao me registrar, concordo em compartilhar meus dados aqui informados, com a finalidade única de ser contatado pela Embrapa.

                Entendo que a gestão de meus dados pela Embrapa segue as diretrizes da Lei LGPD.
                Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018, acesse <a href="https://bit.ly/3h0gjeV.">https://bit.ly/3h0gjeV.</a> 
            </p>

        </div>
    );

    return (
        <div>
            {
                !adm ? (
                    <Modal
                        open={showDialog}
                        onClose={undefined}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
                ) : (
                    <ListaUsuarios />
                )
            }
        </div>
    );
}

export default App;
